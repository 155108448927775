<template>
  <div>
    <el-skeleton style="width: 800px">
      <template slot="template">
        <el-row :gutter="20">
          <el-col :span="4">
            <el-tag
            type="warning"
            effect="dark"
            size="small"
            style="margin-top:15px"
            >{{ superBlockData.type }}</el-tag>
          </el-col>
          <el-col :span="16">
        <h3 style="text-align: center; font-family: Sans-serif, Verdana; color: black">
          {{ superBlockData.name }}
        </h3>
        </el-col>
        <el-col :span="4">
          <el-button
          style="margin-top:14px"
          type="danger"
          size="mini"
          icon="el-icon-delete"
         @click="deleteBlockChild"
          plain>
          </el-button>
          </el-col>
        </el-row>
        <div class="center">
          <el-form class="panel">
          <el-row :gutter="20">
            <el-col>
                <div class="block">
                 <el-carousel indicator-position="outside" v-if="superBlockData && superBlockData.data && Object.prototype.hasOwnProperty.call(superBlockData.data, 'images')">
                <el-carousel-item v-for="(item, index) in superBlockData.data.images" :key="index">
                  <img
                   class="center" :src="item.image" />
                </el-carousel-item>
                </el-carousel>
                </div>
            </el-col>
          </el-row>
          </el-form>
        </div>
      </template>
    </el-skeleton>
  </div>
</template>

<script>
export default {
  props: {
    superBlockData: {
      type: Object,
      default: () => {}
    },
    deleteBlock: {
      type: Function,
      default: () => { }
    }
  },
  data () {
    return {}
  },
  methods: {
    deleteBlockChild () {
      this.$emit('deleteBlock')
    }
  }
}
</script>
<style scoped>
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  margin-top: 30px;
  margin-bottom: 30px;
}
</style>
