<template>
  <div>
    <div class="panel-item">
      <el-row :gutter="20">
        <el-col :span="8">
          <banner :bannerData="bannerData" @deleteBanner="deleteItem"></banner>
          <draggable v-model="currentData" @change="sendResultValues">
            <transition-group>
              <div v-for="(element, index) in currentData" :key="index">
                <el-row v-if="element.type === 'superBlock'" :gutter="20">
                  <superBlock :superBlockData="element" @deleteBlock="deleteItem(index)"></superBlock>
                </el-row>
                <el-row v-else-if="element.type === 'productList'" :gutter="20">
                  <el-skeleton style="width: 800px;">
                    <template slot="template">
                      <h3 style="text-align: center; font-family: Sans-serif, Verdana; color: black;">{{ element.name }}
                      </h3>
                      <div style="padding: 14px;">
                        <el-skeleton-item variant="caption" style="width: 20%;" />
                        <div style="display: flex; align-items: center; justify-items: space-between;">
                          <el-skeleton-item variant="image" style="width: 100px; height: 100px;" />
                          <el-skeleton-item variant="image" style="width: 100px; height: 100px; margin-left: 15px;" />
                          <el-skeleton-item variant="image" style="width: 100px; height: 100px; margin-left: 15px;" />
                          <el-skeleton-item variant="image" style="width: 100px; height: 100px; margin-left: 15px;" />
                          <el-skeleton-item variant="image" style="width: 100px; height: 100px; margin-left: 15px;" />
                        </div>
                      </div>
                    </template>
                  </el-skeleton>
                </el-row>
              </div>
            </transition-group>
          </draggable>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import banner from './banner.vue'
import superBlock from './superBlock.vue'
import draggable from 'vuedraggable'
export default {
  name: 'pageItems',
  components: {
    superBlock,
    banner,
    draggable
  },
  watch: {
    data (oldVal, newVal) {
      this.currentData = JSON.parse(JSON.stringify(newVal))
      this.currentData = oldVal
      if (oldVal.length > 0) {
        this.bannerData = []
        oldVal.forEach(el => {
          if (el.type === 'banner') {
            this.bannerData.push(el.data)
          }
        })
      } else if (oldVal.length === 0) {
        this.bannerData = []
        this.currentData = []
      }
    }
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    updateFunction: Function,
    is_active: {
      type: Boolean,
      default: false
    },
    title: {
      require: true,
      type: String
    },
    type: {
      require: true,
      type: String
    }
  },
  data () {
    return {
      currentData: [],
      bannerData: []
    }
  },
  methods: {
    sendResultValues: function () {
      // this.$emit('update:data', this.data)
      this.updateFunction(this.currentData)
    },
    deleteItem (index) {
      this.currentData.splice(index, 1)
    },
    deleteBlock (item) {
      console.log(item)
    }
  }
}

</script>
