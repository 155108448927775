<template>
  <div>
    <div class="fixed-top-action">
      <el-row type="flex" align="middle">
        <el-col :span="12" class="pl20">
          <div class="status-text">Unsaved changes</div>
        </el-col>
        <el-col :span="12" class="text-right pr20">
          <el-button type="danger" @click="confirmLeaveForm = true"
            >Болих</el-button
          >
          <el-button type="success" @click="onPressAddPage(pageForm)">Хадгалах</el-button>
        </el-col>
      </el-row>
    </div>
    <custom-header :title="'Шинэ page үүсгэх'"/>
    <el-row>
      <el-col :span="20" :offset="2">
        <!-- form error template -->
        <div class="form-error-container mb20" v-if="errorArray.length != 0">
          <header>Уучлаарай {{errorArray.length}} зүйл алдаатай байна</header>
          <ul class="error-list">
            <div v-for="(error, index) in errorArray" :key="index">
              <li>{{error.value}}</li>
            </div>
          </ul>
        </div>
        <el-form label-position="top" :model="pageForm" ref="pageForm" :rules="Rules" v-loading="loading">
          <el-row :gutter="20">
            <el-col :span="16">
              <div class="panel">
                <div class="panel-item">
                  <h1 style="font-family: Monospace, Monaco; color: black">{{pageForm.name}}</h1>
                </div>
                <page-items :data="pageForm.items" @update:data="dataUpdate" :updateFunction="dataUpdate"></page-items>
              </div>
            </el-col>
            <div class="panel-item">
              <el-col :span="8">
                <el-row :gutter="20">
                  <el-button style="width: 100%; margin-top: 20px" type="primary" :disabled="pageForm.is_editable === true ? isDisabled = false : isDisabled = true" @click="addBanner">Баннер нэмэх</el-button>
                </el-row>
                <el-row :gutter="20">
                  <el-button style="width: 100%; margin-top: 20px" type="warning" :disabled="pageForm.is_editable === true ? isDisabled = false : isDisabled = true" @click="addSuperBlock">Блок нэмэх</el-button>
                </el-row>
                <el-row :gutter="20">
                  <el-button style="width: 100%; margin-top: 20px" type="danger" :disabled="pageForm.is_editable === true ? isDisabled = false : isDisabled = true" @click="addProductList">Бүтээгдэхүүний жагсаалт нэмэх</el-button>
                </el-row>
                <el-form>
                  <div class="panel" style="margin-top: 30px">
                    <div class="panel-item">
                      <el-row :gutter="20">
                        <el-col :span="12">
                          <h4>Нэр</h4>
                          <el-input :disabled="pageForm.is_editable === true ? isDisabled = false : isDisabled = true"
                           type="text" size="mini" placeholder="Нэр оруулна уу..." v-model="pageForm.name"></el-input>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20">
                        <el-col :span="12">
                          <h4>Төлөв</h4>
                          <el-switch v-model="pageForm.is_active"
                          :disabled="pageForm.is_editable === true ? isDisabled = false : isDisabled = true"
                            active-text="Идэвхтэй" inactive-text="Идэвхгүй">
                          </el-switch>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20">
                        <el-col :span="12" style="margin-top: 20px">
                          <h4>Badge харагдах хэсэг</h4>
                          <el-switch
                           v-model="pageForm.is_badge_show"
                          :disabled="pageForm.is_editable === true ? isDisabled = false : isDisabled = true"
                            active-text="Идэвхтэй" inactive-text="Идэвхгүй">
                          </el-switch>
                        </el-col>
                      </el-row>
                      <div v-if="pageForm.is_badge_show === true">
                      <el-row :gutter="20">
                        <el-col :span="12" style="margin-top: 20px">
                          <h4>Badge текст</h4>
                          <el-input :disabled="pageForm.is_editable === true ? isDisabled = false : isDisabled = true"
                           type="text" size="mini" placeholder="Badge text" v-model="pageForm.badge.badge_text"></el-input>
                        </el-col>
                        <el-col :span="12" class="mt70" v-if="pageForm && pageForm.badge && pageForm.badge.badge_text !== ''">
                          <div :style="textStyles" class="colors">
                          {{pageForm.badge.badge_text}}
                          </div>
                        </el-col>
                        </el-row>
                        <el-row :gutter="20">
                        <el-row>
                          <el-col :span="12" style="margin-top: 20px">
                            <h4>Badge өнгө</h4>
                            <div class="block">
                            <el-color-picker :disabled="pageForm.is_editable === true ? isDisabled = false : isDisabled = true" v-model="pageForm.badge.badge_color"></el-color-picker>
                            </div>
                          </el-col>
                          <el-col :span="12" style="margin-top: 20px">
                            <h4>Badge текст өнгө</h4>
                            <el-color-picker :disabled="pageForm.is_editable === true ? isDisabled = false : isDisabled = true" v-model="pageForm.badge.badge_text_color"></el-color-picker>
                          </el-col>
                        </el-row>
                      </el-row>
                      </div>
                      <el-row :gutter="20">
                        <el-col :span="12" style="margin-top: 20px">
                          <h4>Menu харагдах хэсэг</h4>
                          <el-switch :disabled="pageForm.is_editable === true ? isDisabled = false : isDisabled = true"
                          v-model="pageForm.is_menu_show"
                            active-text="Идэвхтэй" inactive-text="Идэвхгүй">
                          </el-switch>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20">
                        <el-col :span="12" style="margin-top: 20px">
                          <h4>Mobile-д харагдах эсэх</h4>
                          <el-switch :disabled="pageForm.is_editable === true ? isDisabled = false : isDisabled = true"
                          v-model="pageForm.is_mobile"
                            active-text="Идэвхтэй" inactive-text="Идэвхгүй">
                          </el-switch>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20" style="margin-top: 20px">
                        <el-col :span="12">
                          <h4>Засварлах боломжтой эсэх</h4>
                          <el-switch v-model="pageForm.is_editable" active-text="Тийм" inactive-text="Үгүй">
                          </el-switch>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20" style="margin-top: 20px">
                        <el-col :span="12">
                          <h4>Эрэмбэ</h4>
                          <el-input :disabled="pageForm.is_editable === true ? isDisabled = false : isDisabled = true"
                          type="text" size="mini" placeholder="menu sort" v-model="pageForm.menu_sort"></el-input>
                        </el-col>
                        <el-col :span="12">
                          <h4>Slug</h4>
                          <el-form :model="pageForm" :rules="rules2" ref="pageForm">
                            <el-form-item prop="slug">
                              <el-input auto-complete="off" :disabled="pageForm.is_editable === true ? isDisabled = false : isDisabled = true"
                              type="text" size="mini" placeholder="slug" v-model="pageForm.slug"></el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20" style="margin-top: 20px">
                        <el-col :span="12">
                          <h4>Эхлэх өдөр:</h4>
                            <el-date-picker
                            :disabled="pageForm.is_editable === true ? isDisabled = false : isDisabled = true"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              size="mini"
                              v-model="pageForm.start_date"
                              type="datetime"
                              placeholder="Эхлэх хугацаа">
                            </el-date-picker>
                        </el-col>
                        </el-row>
                        <el-row :gutter="20">
                        <el-col :span="12">
                          <h4>Хугацаа дуусах огноо:</h4>
                          <el-date-picker
                          :disabled="pageForm.is_editable === true ? isDisabled = false : isDisabled = true"
                          size="mini"
                          @input="$forceUpdate()"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          v-model="pageForm.expired_date"
                          type="datetime"
                          placeholder="Дуусах хугацаа">
                        </el-date-picker>
                        </el-col>
                        </el-row>
                    </div>
                  </div>
                </el-form>
                <div class="text-right">
          <el-button style="margin-top: 20px" type="defult" @click="goBack">Буцах</el-button>
          <el-button type="success" @click="onPressAddPage(pageForm)">Хадгалах</el-button>
        </div>
              </el-col>
            </div>
          </el-row>
        </el-form>
      </el-col>
    </el-row>
    <el-dialog :title="item.type==='banner' ? 'Баннер нэмэх' : 'Супер блок'" :visible.sync="dialogVisible" width="30%">
      <el-form label-position="right" label-width="100px" :model="item">
        <el-form-item v-if="item.type === 'banner'" label="Нэр">
          <el-input placeholder="Нэрээ оруулна уу..." v-model="item.name"></el-input>
        </el-form-item>
        <el-form-item v-if="item.type === 'banner'" label="Data">
          <el-select value-key="id" remote :remote-method="getBanner" v-model="item.data_id" filterable placeholder="сонгох"
            class="block" @change="selectedBanner(banners)">
            <el-option
            v-for="(banner, index) in banners"
            :key="index" :label="banner.title"
            :value="banner.id">
            <span style="float: left">{{ banner.title }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ banner.banner_code }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="item.type === 'superBlock'" label="Нэр">
          <el-input mini placeholder="Нэрээ оруулна уу..." v-model="item.name"></el-input>
        </el-form-item>
        <el-form-item v-if="item.type === 'superBlock'" label="data">
          <el-select value-key="super_block_id" filterable v-model="item.data" placeholder="сонгох"
            class="block">
            <el-option
            v-for="(block, index1) in blockData"
            :key="index1"
            :label="block.name_mon"
            :value="block">
            <span style="float: left">{{ block.name_mon }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ block.super_block_code }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="item.type === 'productList'" label="Нэр">
          <el-select v-model="name_mon" placeholder="сонгох" class="block">
            <el-option>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="item.type === 'productList'" label="type">
          <el-input size="mini" v-model="item.type"></el-input>
        </el-form-item>
        <el-form-item v-if="item.type === 'productList'" label="redirect_url">
          <el-input size="mini" v-model="item.redirect_url"></el-input>
        </el-form-item>
        <el-form-item v-if="item.type === 'productList'" label="Төлөв">
          <el-switch v-model="item.is_active" active-text="Идвэхтэй" inactive-text="Идвэхгүй">
          </el-switch>
        </el-form-item>
      </el-form>
      <span v-if="item.type === 'banner'" slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="addBannerItem">Нэмэх</el-button>
      </span>
      <span v-if="item.type === 'superBlock'" slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">Cancel</el-button>
        <el-button type="warning" @click="addBlockItem">Нэмэх</el-button>
      </span>
      <span v-if="item.type === 'productList'" slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">Cancel</el-button>
        <el-button type="danger" @click="addProductListItem">Нэмэх</el-button>
      </span>
    </el-dialog>
     <!-- Confirm discard changes dialog -->
    <el-dialog
      title="Discard all unsaved changes"
      :visible.sync="confirmLeaveForm"
      width="50%"
    >
      <span
        >If you discard changes, you’ll delete any edits you made since you last
        saved.</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmLeaveForm = false" class="mr10"
          >Continue editing</el-button
        >
        <router-link to="/page-list">
          <el-button type="danger" @click="confirmLeaveForm = false"
            >Discard changes</el-button
          >
        </router-link>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import pageItems from '../../../pages/page/components/pageItems.vue'
import customHeader from '../../../../components/common/customHeader.vue'
import services from '../../../../helpers/services'
export default {
  components: {
    customHeader,
    pageItems
  },
  created () {
    this.getBanner()
    this.getBlocks()
  },
  watch: {
    'pageForm.items': function (val) {
      this.pageForm.items.forEach((item, index) => {
        this.pageForm.items[index].sort = index
      })
    }
  },
  computed: {
    textStyles () {
      return {
        'background-color': this.pageForm.badge.badge_color,
        color: this.pageForm.badge.badge_text_color
      }
    }
  },
  data () {
    var validaePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please input the password'))
      } else {
        if (value !== value.toLowerCase()) {
          callback(new Error('Дан жижиг үсэг байх ёстой'))
          this.slugDisable = true
        } else if (value.includes(' ')) {
          callback(new Error('Зай авахгүй байх ёстой'))
          this.slugDisable = true
        } else if (this.pageForm.slug !== '') {
          this.$refs.pageForm.validateField('slug')
          this.slugDisable = false
        }
        callback()
      }
    }
    return {
      slugDisable: false,
      rules2: {
        slug: [
          { validator: validaePass, trigger: 'blur' }
        ]
      },
      confirmLeaveForm: false,
      dialogVisible: false,
      item: {},
      pageForm: {
        is_menu_show: false,
        menu_sort: 0,
        is_active: true,
        is_deleted: false,
        name: '',
        is_badge_show: false,
        is_editable: true,
        badge: {
          badge_color: null,
          badge_text: '',
          badge_text_color: null
        },
        items: [
        ],
        slug: '',
        expired_date: '',
        redirect_url: null,
        start_date: ''
      },
      banners: [],
      blockData: [],
      errorArray: [],
      loading: false,
      Rules: {
        name: [
          { required: true, message: ' нэрээ оруулна уу', trigger: 'blur' }
        ],
        sort: [
          { required: true, message: 'Эрэмбэ оруулна уу', trigger: 'blur' }
        ],
        is_active: [
          { required: true, message: 'Төлөв заавал сонгоно уу', trigger: 'blur' }
        ],
        start_date: [
          { required: true, message: 'Эхлэх хугацаа оруулна уу', trigger: 'blur' }
        ],
        expired_date: [
          { required: true, message: 'Эхлэх хугацаа оруулна уу', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    dataUpdate: function (data) {
      this.pageForm.items = data
    },
    selectedBanner (banners) {
      this.item.data = banners.find(x => x.id === this.item.data_id)
    },
    addItem () {
      this.item.id = this.pageForm.items.length + 1
      this.pageForm.items.push(this.item)
      this.dialogVisible = false
    },
    addBanner () {
      this.item = {
        type: 'banner'
      }
      this.dialogVisible = true
    },
    addSuperBlock () {
      this.item = {
        type: 'superBlock'
      }
      this.dialogVisible = true
    },
    addProductList () {
      this.item = {
        type: 'productList'
      }
      this.dialogVisible = true
    },
    addBannerItem () {
      this.pageForm.items.push(this.item)
      this.dialogVisible = false
    },
    addBlockItem () {
      const tempData = JSON.parse(JSON.stringify(this.item))
      // tempData.id = this.item.id
      tempData.id = tempData.data.super_block_id
      tempData.data_id = tempData.id
      delete tempData.id
      this.pageForm.items.push(tempData)
      this.dialogVisible = false
    },
    addProductListItem () {
      this.pageForm.items.push(this.item)
      this.dialogVisible = false
    },
    onPressAddPage () {
      services.onPressAddPage(this.pageForm).then((response) => {
        this.loading = false
        this.alertReporter(
          response.status === 'success' ? 'Амжилттай' : 'Уучлаарай',
          response.message,
          response.status
        )
        if (response.status === 'success') {
          this.loading = false
          this.$router.push({
            name: 'pageList'
          })
        } else if (response.status === 'error') {
          this.alertReporter('Алдаа', response.message, 'error')
        } else {
          return false
        }
      })
    },
    goBack () {
      this.$router.go(-1)
    },
    getBanner (query) {
      this.loading = true
      services.getBanner(1000, 0, query).then(response => {
        if (response.status === 'success') {
          this.totalCount = response.total
          this.banners = response.data
          this.loading = false
        } else {
          this.loading = false
        }
      })
    },
    getBlocks () {
      const query = '?size=1000&from=0&search_text='
      this.isLoading = true
      services.getBlocks(query).then(response => {
        this.blockData = response.datas
        this.totalCount = response.total
        this.isLoading = false
      })
    },
    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'bottom-left'
      })
    }
  }
}
</script>
<style scoped>
.colors {
  padding: 10px;
  text-align: center;
  border-radius: 10px;
}
</style>
